import { v4 as uuidv4, } from 'uuid';
import DOMPurify from 'dompurify';
import { SiJavascript, SiRedux, SiTailwindcss, SiPostgresql, SiFirebase, SiTypescript, SiFlutter } from 'react-icons/si';
import { AiOutlineMail, AiOutlineFilePdf } from 'react-icons/ai';
import { DiNodejs } from 'react-icons/di';
import { FaHtml5, FaCss3, FaReact, FaNpm, FaGithub, FaLinkedin } from 'react-icons/fa'
import resume from '../assets/aguila_resume.pdf'


const navlinks = [
    {
        id: uuidv4(),
        name: '< skills />',
        path: 'skills'
    },
    {
        id: uuidv4(),
        name: '< projects />',
        path: 'projects'
    },
    {
        id: uuidv4(),
        name: '< education />',
        path: 'education'
    },
    {
        id: uuidv4(),
        name: '< contact />',
        path: 'contact'
    }
]

const greeting = {
    image: '',
    name: ' Nick Aguila ',
    description: DOMPurify.sanitize(`
        is a dedicated <strong>Software Developer</strong>
        who <em>enjoys exploring</em> the dynamic blend of 
        city living and outdoor recreation on <strong>two wheels</strong>
    `)
}

// Social Media Links

const socialMediaLinks = {
    socials: [
        {
            id: uuidv4(),
            name: 'email',
            link: "aguilawebdev@gmail.com",
            Icon: AiOutlineMail,
            color: '#4bb4dd',
            background: '#f5f5f5',
            sidebarColor: '#000'
        },

        {
            id: uuidv4(),
            name: 'github',
            link: "https://github.com/aguilana",
            Icon: FaGithub
        },

        {
            id: uuidv4(),
            name: 'linkedin',
            link: "https://www.linkedin.com/in/nickaguila/",
            Icon: FaLinkedin,
            color: '#0A66C2',
            background: '#0A66C2',
            sidebarColor: '#f5f5f5'
        },
        {
            id: uuidv4(),
            name: 'resume',
            link: resume,
            Icon: AiOutlineFilePdf,
            color: '#fff',
            // background: '#fff',
            // sidebarColor: '#000'
        }
    ],
    // Instagram, Twitter and Kaggle are also supported in the links!
    // To customize icons and social links, tweak src/components/SocialMedia
    display: true // Set true to display this section, defaults to false
};

const skillsSection = {
    title: "What I do",
    subTitle: "Passionate Full Stack Developer who learns fast and dives deep into efficient tech",
    skills: [
        "⚡ Develop highly interactive Full Stack / Front End / User Interfaces for web and mobile applications"
        ,
        "⚡ Building and consuming RESTful APIs to interact with back-end services",

        "⚡ Integration of third party services"
    ],
    softwareSkills: [
        {
            id: uuidv4(),
            skillName: "javascript",
            Icon: SiJavascript,
            color: '#f0db4f	'
        },
        {
            id: uuidv4(),
            skillName: "typescript",
            Icon: SiTypescript,
            color: '#007acc'
        },
        {
            id: uuidv4(),
            skillName: "css3",
            Icon: FaCss3,
            color: '#398df0'
        },
        {
            id: uuidv4(),
            skillName: "html-5",
            Icon: FaHtml5,
            color: '#f7a255'
        },
        {
            id: uuidv4(),
            skillName: "reactjs",
            Icon: FaReact,
            color: '#149ECA'
        },
        {
            id: uuidv4(),
            skillName: "flutter",
            Icon: SiFlutter,
            color: '#02569B'
        },
        {
            id: uuidv4(),
            skillName: "redux",
            Icon: SiRedux,
            color: '#BA8FFF'
        },
        {
            id: uuidv4(),
            skillName: "nodejs",
            Icon: DiNodejs,
            color: '#43853d'
        },
        {
            id: uuidv4(),
            skillName: "npm",
            Icon: FaNpm,
            color: '#cb0000'
        },
        {
            id: uuidv4(),
            skillName: "postgreSQL",
            Icon: SiPostgresql,
            color: '#336791'
        },
        {
            id: uuidv4(),
            skillName: 'tailwind',
            Icon: SiTailwindcss,
            color: '#0EA5E9'
        },
        {
            id: uuidv4(),
            skillName: "firebase",
            Icon: SiFirebase,
            color: '#F57D01'
        },
    ],
    display: true // Set false to hide this section, defaults to true
};

const educationInfo = {
    display: true, // Set false to hide this section, defaults to true
    schools: [
        {
            schoolName: "Fullstack Academy",
            logo: '',
            subHeader: "Full Stack Web Development Bootcamp",
            duration: "September 2022 - February 2023",
            desc: "Fulltime Immersive Bootcamp covering the Software Development Life Cycle, version control with Git, Kanban, along with HTML, CSS, JS, React, Node, and PostgreSQL",
            descBullets: [
                "🔖  Built high-performance, scalable full-stack applications with a team using Kanban and Git for version control",
                "🔖 Emphasized optimized application performance through the use of efficient algorithms and code architecture",
                "🔖 Practiced pair programming techniques to enhance application efficiency and scalability"
            ]
        },
        {
            schoolName: "Adams State University",
            logo: require('../assets/asu.png').default,
            subHeader: "Master of Science",
            duration: "September 2012 - May 2014",
            desc: "Participated in the research of XXX and successfully defended Master's Thesis.",
            descBullets: [
                "🔖 Managed and operated the exercise science lab, providing guidance and support to professors",
                "🔖 Introduced new technologies, such as the Velotron tech bike, and trained professors on how to use them for future research projects",
                "🔖 Worked as a teaching assistant for an introductory science course for freshmen students"

            ]
        },
        {
            schoolName: "University of Connecticut",
            logo: require('../assets/uconn.png').default,
            subHeader: "Bachelor of Science",
            duration: "August 2008 - May 2012",
            desc: "Ranked top 10% in the program. Assisted in two human research doctoral research experiements utilizing the latest technology to analyze participants values",
            descBullets: [
                "🔖 Served as NCAA D1 athlete and twice as cross country captain, demonstrating strong leadership and team-building skills",
                "🔖 Assisted in doctoral research studies through data entry and performing basic lab assistant roles",
                "🔖 Gained valuable experience working in a research lab, which helped develop research skills and attention to detail"]
        }
    ]
};

const projects = {
    display: true,
    projects: [
        {
            id: uuidv4(),
            name: 'Aguila Performance',
            link: 'https://www.aguilaperformance.com/',
            // github: 'https://github.com/aguilana/aguilaperformance',
            imageFront: 'https://personal-porfolio.s3.amazonaws.com/projects/ap-home.jpg',
            imageBack: 'https://personal-porfolio.s3.amazonaws.com/projects/ap-home-2.jpg',
            desc: 'Personal Coaching Website',
            details: ['Increased Client Engagement', '120% annual revenue growth', 'Engaging UI'],
            year: 2022
        },
        {
            id: uuidv4(),
            name: 'Supperclub',
            link: 'https://supperclub.herokuapp.com/',
            github: 'https://github.com/aguilana/supperclub-v1.1',
            imageFront: 'https://personal-porfolio.s3.amazonaws.com/projects/supperclub-home.jpg',
            imageBack: 'https://personal-porfolio.s3.amazonaws.com/projects/supperclub-map.jpg',
            desc: 'Connecting Chefs and Foodies',
            details: ['Fullstack Web App', 'Unique Dining Experience', 'Culinary Networking'],
            year: 2023,
        },
        {
            id: uuidv4(),
            name: 'Dashboarding',
            // link: '',
            github: 'https://github.com/aguilana/dashboard',
            imageFront: 'https://personal-porfolio.s3.amazonaws.com/projects/dashboard-home.jpg',
            imageBack: 'https://personal-porfolio.s3.amazonaws.com/projects/dashboard-iss.jpg',
            desc: 'Visualize with Charts',
            details: ['Data Visualization', 'Real Estate Insights', 'Interactive Charts'],
            year: 2023
        },
    ]
}

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

// CSS Properties
const override = {
    display: "block",
    width: '100vw',
    height: '100vh',
    margin: "auto",
    top: 100,
    position: 'absolute',
    textAlign: 'center',
    alignItems: 'center',
    background: 'transparent',
};

export { navlinks, greeting, projects, skillsSection, socialMediaLinks, educationInfo, override }