import React, { useState, useEffect } from 'react'
import { TbHandFinger } from 'react-icons/tb'

const TopButton = () => {

    const [isVisible, setIsVisible] = useState(false)
    const [idle, setIdle] = useState(Date.now())

    useEffect(() => {
        const handleScroll = () => {
            setIdle(Date.now());
            if (window.pageYOffset > 40) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const hideButton = setTimeout(() => {
            setIsVisible(false);
        }, 8000);

        return () => {
            clearTimeout(hideButton);
        };
    }, [idle]);

    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <>
            {isVisible && (
                <button
                    onClick={handleClick}
                    title='scroll to top'
                    className='glowing-text fixed bottom-5 md:bottom-28 right-7 z-50 outline-none cursor-pointer p-3 rounded-full text-4xl transition-all duration-300 bg-slate-900 hover:bg-slate-800 group'
                >
                    <TbHandFinger className="transform transition-all duration-300 -rotate-[30deg] group-hover:rotate-0" />
                </button>
            )}
        </>
    )
}

export default TopButton