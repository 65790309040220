import React, { useState } from 'react'
import './flippable-card.css';
import Card from './Card.js';
import { CSSTransition } from 'react-transition-group';



const ProjectFlip = ({ project }) => {

    const [showFront, setShowFront] = useState(true);

    const handleClick = (ev) => {
        if (ev.target.tagName.toLowerCase() === 'a') return; // ignore links
        setShowFront((prevShowFront) => !prevShowFront);
    }

    return (
        <div className="flippable-card-container">
            <CSSTransition
                in={showFront}
                timeout={300}
                classNames='flip'
            >
                <Card project={project} onClick={() => {
                    setShowFront((v) => !v);
                }} />

            </CSSTransition>
        </div>
    );
}

export default ProjectFlip