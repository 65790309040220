import React from 'react'
import { skillsSection } from '../../constants/portfolio'

const SoftwareSkills = () => {
    return (
        <div className='py-4'>
            <ul className='grid grid-cols-3 text-base md:grid-cols-4'>
                {skillsSection.softwareSkills.map((skill, i) => (
                    <li key={skill.id} className='flex flex-col items-start p-4 gap-2'>
                        <skill.Icon size={40} style={{ color: skill.color }} />
                        <p className='text-sm sm:text-xl'><em>{skill.skillName}</em></p>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default SoftwareSkills