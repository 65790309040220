import React from 'react';

const FullstackSvg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        className="h-24 w-24 mr-3 text-red-500"
        viewBox="0 0 35 35"
        {...props}
    >
        <path
            fill="currentColor"
            d="m13.853 24.182 3.639-3.639 3.639 3.639-3.64 3.639-3.638-3.64zm-6.674-6.69L17.492 7.179 27.82 17.51l-3.067 3.067-7.245-7.245-7.26 7.228-3.069-3.067zM17.492 0 0 17.492l17.492 17.492 17.492-17.492L17.492 0z"
        />
    </svg>
)

export default FullstackSvg