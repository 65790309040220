import React, { useState } from 'react'
import { greeting } from '../../constants/portfolio.js'
import './about.css'

const About = ({ aboutLoading, smallNav }) => {

    const [loaded, setLoaded] = useState(false)


    return (

        <section className={`relative md:mx-auto`}>
            <div className='absolute top-0 left-0 -z-10 blur-sm w-full h-screen'>
                <img src={'https://personal-porfolio.s3.amazonaws.com/portfolio-image.jpg'} alt="image from unsplash by quinton coetzee" className='w-full h-full object-cover object-right opacity-80' />
            </div>
            <div className={`about-page text-slate-100 bg-transparent min-h-screen max-w-[1240px] pl-9 pr-6 mr-6 text-4xl tracking-wide md:text-5xl md:mx-auto leading-[50px] lg:pl-16 ${smallNav && 'opacity-10'} ${aboutLoading && 'about-loaded'} flex justify-center items-center `}>
                <div className='w-full absolute top-[20%]'>

                    <div className='md:mb-4'><strong className='gradient__text'><em>{greeting.name}</em></strong></div>
                    <div className='md:mb-4'>is a driven  <em><strong className=' gradient__text-two'>Software Engineer</strong></em> who </div>
                    <div className='md:mb-4'>enjoys exploring the dynamic blend of </div>
                    <div className='md:mb-4'>city living, <span >outdoor rec</span>, and <span className=' gradient__text-two'><strong><em>valuable tech</em></strong></span></div>
                    <div>on <strong className='gradient__text cycling pr-1'><em>two wheels</em></strong></div>

                </div>
            </div>

        </section>

    )
}

export default About