import React, { useEffect } from 'react'
import { Link } from 'react-scroll'
import './navbar.css'

const SmallNav = ({ navlinks, smallNav }) => {

    useEffect(() => {
        if (smallNav) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [smallNav]);

    return (
        <nav className={`smallnav flex flex-col flex-1 gap-4 justify-start items-center transition-all duration-300 ease-in transform top-0 right-0 mt-40 ${smallNav ? 'min-h-screen translate-y-8 block z-50 w-full' : '-translate-y-[600px] -z-10'}`}>
            {navlinks?.map((link) => (
                <Link key={link.id} to={link.path} smooth={true} duration={540} className={`mr-6 p-2 under-link cursor-pointer text-2xl`}><em>{link.name}</em></Link>
            ))}
        </nav>
    )
}

export default SmallNav