import React from 'react'
import { skillsSection } from '../../constants/portfolio'
import SoftwareSkills from './SoftwareSkills'
import { Slide } from "react-awesome-reveal";
import { allSections, sectionHeading } from '../../styles/section.styles'

const Skills = () => {
    return (
        <section id='skills' className='' >
            <div className={`${allSections}`}>
                <Slide duration={1200} triggerOnce >
                    <h2 name='skills' className={`${sectionHeading}`}><strong>Skills</strong></h2>
                    <div>
                        <h3 className='text-3xl pb-2'>{skillsSection.title}</h3>
                        <p className='text-2xl'>{skillsSection.subTitle}</p>
                        {/* DISPLAY SKILLS HERE */}
                        <SoftwareSkills />
                        {skillsSection.skills.map((skill, i) => (
                            <p key={i} className='md:ml-6 py-2'> {skill}</p>
                        ))}
                    </div>
                </Slide>
            </div>

        </section >
    )
}

export default Skills