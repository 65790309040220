import React from "react";
import { FaGithub, FaLink } from 'react-icons/fa'
import { MdOutlineFlipCameraAndroid } from 'react-icons/md'
import "./flippable-card.css";

function Card({ project, onClick }) {
    return (
        <div className="card" onClick={onClick}>
            {/* figure */}
            <figure className="card-back">

                <div className='w-full'>
                    <h2 className="text-2xl">{project.desc}</h2>
                    <div className='flex justify-center text-center w-full pt-2'>
                        <MdOutlineFlipCameraAndroid size={30} />
                    </div>
                </div>

                <div className='h-[350px] w-[350px] rounded-3xl'>
                    <img src={project.imageBack} alt="" className='object-cover h-[350px] w-[350px] rounded-3xl flex-1' />
                </div>

                <div className='flex justify-evenly items-center w-full project-links'>
                    {project.link && (
                        <a href={project?.link}
                            rel="noopener noreferrer">
                            <div title='link to live link' className='flex flex-col items-center gap-2 text-xl md:px-4 hover:opacity-40 '>
                                <FaLink size={30} />
                                <p> Live Link </p>
                            </div>
                        </a>)
                    }
                    {project.github && (
                        <a href={project?.github}
                            rel="noopener noreferrer">
                            <div title='link to github code' className='flex flex-col items-center gap-2 text-xl md:px-4  hover:opacity-40 '>
                                <FaGithub size={30} />
                                <p> Github Link </p>
                            </div>
                        </a>)
                    }

                </div>

            </figure>

            <figure className="card-front">
                <div className='flex flex-col justify-center text-center w-full'>
                    <h2 className="text-3xl"><strong>{project.name}</strong></h2>
                    <div className='flex justify-center text-center w-full pt-2'>
                        <MdOutlineFlipCameraAndroid size={30} />
                    </div>
                </div>
                <div className='h-[350px] w-[350px] rounded-3xl'>
                    <img src={project.imageFront} alt="" className='object-cover h-full w-full rounded-3xl flex-1' />
                </div>
                <ul className='text-xl flex flex-col justify-start items-start list-disc ml-2'>
                    {project?.details.map((detail, idx) => (
                        <li key={idx}> {detail} </li>
                    ))}
                </ul>
            </figure>
        </div>
    );
}

export default Card;