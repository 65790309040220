import React from 'react'
import { FaCat } from 'react-icons/fa'
import Socials from './Socials'

const Contact = ({ socialMediaLinks, handleEmailClick }) => {

    return (
        <section className='max-w-[1240px] mx-auto pl-9 pr-8 py-10 mt-20'>
            <div className='container'>
                <div className='contact flex flex-col gap-6'>
                    <div className="contact-head">
                        <h3 className='contact-heading text-4xl text-center md:text-left gradient__text z-10'>
                            <span> Let's </span> 👍 <span> get in touch!</span>
                        </h3>
                        <p className="contact-paragraph"></p>
                    </div>
                    <div className='contact-connect flex flex-col gap-6 md:flex-row justify-between text-xs'>
                        <div className="contact-copyright flex flex-col gap-2 md:justify-start md:flex-row justify-center items-center">
                            <p className='text-center'>&#169; Made with 💝 by Nick A and the best pair programmer</p>
                            <p className='flex justify-center'> <FaCat size={30} color='black' /></p>
                            <p className='text-center'>All rights reserved</p>
                            <p className='text-center'>2023</p>
                        </div>
                        <Socials name='contact' socialMediaLinks={socialMediaLinks} handleEmailClick={handleEmailClick} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact