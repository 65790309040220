import React from 'react'
import { educationInfo } from '../../constants/portfolio'
import EducationCard from './EducationCard'
import { allSections, sectionHeading } from '../../styles/section.styles'

const Education = () => {
    if (educationInfo.display)
        return (
            <section
                id='education'
                className={`${allSections}`}
            >
                <h2 className={`${sectionHeading} text-start`}><strong>Education</strong></h2>
                <ul className='flex flex-col gap-6 justify-center'>
                    {educationInfo.schools.map((school, idx) => (
                        <EducationCard key={idx} school={school} />
                    ))}
                </ul>
            </section>
        )
}

export default Education