import React from 'react'
import { projects } from '../../constants/portfolio'
// import IndividualProject from './IndividualProject'
import ProjectFlip from './ProjectFlip'
import { allSections, sectionHeading } from '../../styles/section.styles'

const Projects = () => {

    const { projects: myProjects } = projects
    return (
        <section id='projects' name='projects' className={`${allSections}`}>
            <h2 className={`${sectionHeading}`}> <strong>Projects</strong> </h2>
            <div className='grid lg:grid-cols-2 gap-10 justify-center'>
                {myProjects?.map((project, idx) => (
                    <ProjectFlip key={project.id + idx} project={project} />
                ))}
            </div>
        </section>
    )
}

export default Projects