import React, { useState, useEffect } from "react";
import './index.css'
import BarLoader from "react-spinners/BarLoader";
import {
  Navbar,
  Projects,
  Education,
  About,
  TopButton,
  Skills,
  Contact
} from './components'
import { socialMediaLinks, override } from "./constants/portfolio";
import Sidebar from "./components/SideBar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {

  const [smallNav, setSmallNav] = useState(false)
  const [loading, setLoading] = useState(false)
  const [navbarLoaded, setNavbarLoaded] = useState(false)
  const [aboutLoading, setAboutLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setNavbarLoaded(true)
    setTimeout(() => {
      setLoading(false)
      setAboutLoading(true)
    }, 1500)
  }, [])

  const handleEmailClick = (e) => {
    e.preventDefault();
    const email = socialMediaLinks.socials.find(item => item.name === 'email')
    navigator.clipboard.writeText(email.link)
    toast.success("email copied to clipboard");
    // alert('email copied to clipboard')
  }

  return (
    <>
      <Navbar smallNav={smallNav} setSmallNav={setSmallNav} navbarLoaded={navbarLoaded} />
      {
        loading ? (
          <BarLoader
            color={'#a4bdca'}
            loading={loading}
            size={30}
            cssOverride={override}
            speedMultiplier={0.4}
            aria-label="Loading Spinner"
            data-testid="loader"
          />) : (
          <div className={`absolute top-0 w-full transition-all duration-500 ease-out ${smallNav ? '-z-50 -translate-x-full' : 'translate-x-0'}`}>
            <About aboutLoading={aboutLoading} smallNav={smallNav} />
            <Skills />
            <Projects />
            <Education />
            <Contact socialMediaLinks={socialMediaLinks} handleEmailClick={handleEmailClick} />
          </div>
        )
      }
      <TopButton />
      <Sidebar socialMediaLinks={socialMediaLinks} handleEmailClick={handleEmailClick} navbarLoaded={navbarLoaded} />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="dark"
      />
    </>
  );
};

export default App;
