import React, { createRef } from 'react'
import { FullstackSvg } from '../SVG'



const EducationCard = ({ school }) => {

    const imgRef = createRef()

    const GetDescBullets = ({ descBullets }) => {
        return descBullets
            ? descBullets.map((item, i) => (
                <li key={i} className="ml-10 text-sm gap-2 pb-1">
                    {item}
                </li>
            ))
            : null;
    };

    return (
        <li className='flex flex-col md:flex-row md:justify-start py-6 gap-8 md:gap-24 under-link'>
            <div className='w-full h-24 md:w-32 mr-3 flex flex-row items-center justify-between md:flex'>
                {school.schoolName !== 'Fullstack Academy' ? (
                    <img
                        className='h-24 w-24'
                        ref={imgRef}
                        src={school.logo}
                        loading='lazy'
                        alt={school.schoolName} />
                ) : (
                    <FullstackSvg />
                )}
                <div className='flex flex-col flex-1 text-center md:hidden md:flex-none'>
                    <h3 className='text-2xl'><strong>{school.schoolName}</strong></h3>
                    <h5 className='text-lg py-2 px-4'><em>{school.subHeader}</em></h5>
                </div>
            </div>
            <div className='flex flex-col justify-center items-center md:items-start md:w-full'>
                <h3 className='hidden md:block text-2xl'><strong>{school.schoolName}</strong></h3>
                <div className='flex flex-col justify-center items-center md:items-start'>
                    <h5 className='hidden md:block text-xl py-2 px-4 md:px-0'>{school.subHeader}</h5>
                    {/* <p>{school.desc}</p> */}
                    <div className="education-text-bullets">
                        <ul>
                            <GetDescBullets descBullets={school.descBullets} />
                        </ul>
                    </div>
                </div>
            </div>

        </li>
    )
}

export default EducationCard