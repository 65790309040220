import React from 'react'
import Socials from '../Contact/Socials';
import './sidebar.css'

const Sidebar = ({ socialMediaLinks, handleEmailClick, navbarLoaded }) => {
    return (
        <div className={`hidden sidebar lg:flex fixed flex-col top-[32%] left-[-40px] -translate-x-20 transition-all duration-500 ease-in ${navbarLoaded && ' translate-x-1 duration-300'}`}>
            < Socials name='sidebar' socialMediaLinks={socialMediaLinks} handleEmailClick={handleEmailClick} />
        </div>
    )
}

export default Sidebar