import React, { useEffect, useState } from 'react'
import SmallNav from './SmallNav'
import { AiOutlineClose } from 'react-icons/ai';
import { RiMenu4Fill } from 'react-icons/ri'
import { Link } from 'react-scroll'
import { navlinks } from '../../constants/portfolio';
import './navbar.css'

const Navbar = ({ smallNav, setSmallNav, navbarLoaded }) => {


    const handleOpenSmallNav = () => {
        setSmallNav(!smallNav)
    }

    useEffect(() => {
        const handleResize = () => {
            if (smallNav) {
                setSmallNav(false)
            } else if (window.innerWidth > 768) {
                setSmallNav(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [smallNav])

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setSmallNav(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [smallNav]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const navbar = document.querySelector('.navbar');
            const link = event.target.closest('.under-link');

            if (!navbar.contains(event.target) && (!link || link.offsetTop < 250)) {
                setSmallNav(false);
            }
        };

        window.addEventListener('mousedown', handleOutsideClick);

        return () => {
            window.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [setSmallNav]);

    return (
        <nav className={`navbar text-slate-100 max-w-[1240px] m-auto px-8 pt-6 ${navbarLoaded && 'navbar-loaded'}`}>
            <div className='flex justify-between items-center text-slate-100 md:text-lg lg:text-2xl'>
                <div className=''>
                    <a href="/"> <em> {`< nick aguila / >`} </em> </a>

                </div>
                <div>
                    <nav className='hidden md:flex'>
                        {navlinks?.map(link => (
                            <Link key={link.id} to={link.path} smooth={true} duration={540} className='mr-6 p-0 under-link cursor-pointer'><em>{link.name}</em></Link>
                        ))}
                    </nav>
                    <div className='md:hidden flex mr-6 p-0 cursor-pointer' onClick={handleOpenSmallNav}>
                        {smallNav ? (
                            <div className=' bg-[#1f1f1f60] rounded-full -z-0 p-4'>
                                <AiOutlineClose size={40} name={smallNav ? 'close' : 'menu'} className='transition-opacity duration-300 opacity-100 hover:opacity-75 z-50' />
                            </div>
                        ) : (
                            <div className=' bg-[#1f1f1f60] rounded-full -z-0 p-4'>
                                <RiMenu4Fill size={40} name={smallNav ? 'close' : 'menu'} className='transition-opacity duration-300 opacity-100 hover:opacity-75 transform rotate-45 z-50' />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <SmallNav navlinks={navlinks} smallNav={smallNav} />
        </nav>
    )
}

export default Navbar