import React from 'react'

const Socials = ({ name, socialMediaLinks, handleEmailClick }) => {

    const contactStyle = name === 'contact' && 'grid grid-cols-2 md:grid-cols-4 justify-center items-center gap-4 w-[240px] m-auto'
    const sidebarStyle = name === 'sidebar' && 'w-[80px]'

    return (
        <ul className={`${contactStyle} ${sidebarStyle}`} role='navigation'>
            {socialMediaLinks.socials.map((social, i) => (
                <a
                    key={i}
                    className={`flex items-center ${name === 'contact' && 'under-link flex-col justify-center px-2'} ${name === 'sidebar' && 'h-14 w-20 justify-end group pr-3 transition-transform duration-[425ms] transform hover:translate-x-3 cubic-bezier(.05,.65,.18,1)'}`}
                    // href={social.link.name !== 'email' ? social.link : null}
                    href={social.link}
                    onClick={social.name === 'email' ? handleEmailClick : undefined}
                    title={social.name !== 'resume' ? `find me with ${social.name}` : 'download my resume'}
                    style={name === 'sidebar' ? { background: social?.background } : null}
                    target="_blank"
                    rel="noopener noreferrer"
                    download={social.name === 'resume' ? 'aguila_resume.pdf' : null}

                >
                    <social.Icon size={30} style={name === 'contact' ? { color: social?.color } : { color: social?.sidebarColor }} className={name === 'sidebar' && `transform transition-all rotate-0 group-hover:rotate-[25deg] duration-300`} />
                    {name === 'contact' && (
                        <p className='p-2'><em>{social.name}</em></p>
                    )}
                </a>
            ))}
        </ul>
    )
}

export default Socials